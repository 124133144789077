import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: () => import('../views/home/index.vue'),
      header: () => import('../views/header.vue'),
      footer: () => import('../views/footer.vue')
    },
    meta: {
      title: '首页'
    }
  },
  {
    path: "/help",
    name: 'help',
    components: {
      default: () => import('../views/help/index.vue'),
      header: () => import('../views/header.vue'),
      footer: () => import('../views/footer.vue')
    },
    meta: {
      title: '帮助中心'
    }
  },
  {
    path: "/solution",
    name: 'solution',
    components: {
      default: () => import('../views/solution/index.vue'),
      header: () => import('../views/header.vue'),
      footer: () => import('../views/footer.vue')
    },
    meta: {
      title: '解决方案'
    }
  },
  {
    path: "/download",
    name: 'download',
    components: {
      default: () => import('../views/download/index.vue'),
      header: () => import('../views/header.vue'),
      footer: () => import('../views/footer.vue')
    },
    meta: {
      title: '下载'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/not-found.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
})

export default router

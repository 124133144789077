import { createApp } from 'vue'
import App from './App.vue'
import './static/css/index.css'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'wowjs/css/libs/animate.css'
const app = createApp(App)
// 全局配置
app.use(router)
app.use(ElementPlus)
app.mount('#app')
// createApp(App).use(router).use(ElementPlus).mount('#app')

<template>
  <router-view name="header"></router-view>
  <router-view></router-view>
  <router-view name="footer"></router-view>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  computed,
  onMounted,
  onUnmounted,
  defineComponent,
  getCurrentInstance,
} from "vue";
export default defineComponent({
  name: "App",
  setup() {
    return {};
  },
});
</script>
<style lang="scss" scoped>
</style>